<script setup>
import Sidenav from "@/layout/Sidenav";
import Navbar from "@/layout/Navbars/Navbar.vue";
import AppFooter from "@/layout/Footer.vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import store from "@/store";
import PullRefresh from 'pull-refresh-vue3';
import {onMounted, ref} from "vue";
import {config} from "@/services/config";

const route = useRoute();
useStore();

const loading = ref(false);
const onRefresh = () => {
  location.reload();
};
// Funzione per impostare le variabili CSS
const setCSSVariables = () => {
  const root = document.documentElement;
  // Colori base
  root.style.setProperty('--primary-color', config.colors.primary);
  root.style.setProperty('--secondary-color', config.colors.secondary);
  root.style.setProperty('--success-color', config.colors.success);
  root.style.setProperty('--info-color', config.colors.info);
  root.style.setProperty('--warning-color', config.colors.warning);
  root.style.setProperty('--danger-color', config.colors.danger);

  // Gradienti
  root.style.setProperty('--primary-gradient-start', `${config.colors.primary}CC`); // 80% opacity
  root.style.setProperty('--primary-gradient-end', config.colors.primary);

  // Varianti hover e active
  root.style.setProperty('--primary-hover', adjustColor(config.colors.primary, -10));
  root.style.setProperty('--primary-active', adjustColor(config.colors.primary, -20));

  // Bordi
  root.style.setProperty('--primary-border', config.colors.primary);
  root.style.setProperty('--primary-hover-border', adjustColor(config.colors.primary, -10));
  root.style.setProperty('--primary-active-border', adjustColor(config.colors.primary, -20));
};


// Funzione helper per modificare la luminosità di un colore
const adjustColor = (color, percent) => {
  const num = parseInt(color.replace('#', ''), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = (num >> 8 & 0x00FF) + amt,
      B = (num & 0x0000FF) + amt;
  return '#' + (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255))
      .toString(16)
      .slice(1);
};

onMounted(() => {
  setCSSVariables();
});

</script>
<template>
  <pull-refresh v-model="loading" @refresh="onRefresh" pulling-text="Tira già per aggiornare..." loosing-text="Rilascia per aggiornare..." loading-text="Aggiornamento in corso...">

    <!-- top navigation bar -->
    <navbar v-if="route.name !== 'login' && route.name !== 'forgot-password' && route.name !== 'reset-password'"
            @click.stop.prevent="store.state.showSidenav = false"/>
    <!-- top navigation bar -->

    <!-- offcanvas -->
    <sidenav/>
    <!-- offcanvas -->

    <div class="container-fluid" @click.stop.prevent="store.state.showSidenav = false">
      <div class="row">
        <main class="ml-sm-auto px-md-4 py-4">
          <router-view/>
          <app-footer/>
        </main>
      </div>
    </div>

    <div class="landing-bg h-100 position-fixed w-100"></div>
  </pull-refresh>
</template>
