import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import {getAssetPath, loadBrandCSS} from '@/services/assets'
import { config } from '@/services/config'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './registerServiceWorker'
import AddToHomescreen from '@owliehq/vue-addtohomescreen';
import process from "process";
import 'vue3-easy-data-table/dist/style.css';

// Carica i CSS del brand
loadBrandCSS();

createApp(App)
    .use(VueSweetalert2)
    .use(store)
    .use(router)
    .use(AddToHomescreen, {
        buttonColor: config.colors.primary,
        title: process.env.VUE_APP_APP_NAME,
        content: 'Aggiungi l\'app alla home',
        iconPath: getAssetPath('img/icons/android-chrome-512x512.png'),
    })
    .mount('#app');

let tokenData = localStorage.getItem('tokenData');
if (tokenData) {
    store.commit('user/SET_TOKEN', JSON.parse(atob(tokenData)))
}