const getConfig = () => {
    const brand = process.env.VUE_APP_BRAND;

    try {
        if (brand) {
            // Prova a caricare la configurazione del brand
            return require(`@/assets/brands/${brand}/config.js`);
        }
    } catch (e) {
        // Se non trova la configurazione del brand, non fare nulla
    }

    // Carica la configurazione di default
    return require(`@/assets/default/config.js`);
};

export const config = getConfig();