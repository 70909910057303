// src/services/assets.js

/**
 * Determina se il path è relativo a un'icona o favicon
 * @param path
 * @returns {boolean}
 */
const isIconPath = (path) => {
    return path.includes('favicon.ico') ||
        path.includes('icons/') ||
        path.startsWith('img/icons/');
};

/**
 * Restituisce il percorso corretto per gli asset
 * @param path - Percorso dell'asset richiesto
 * @returns {string|*} - Percorso dell'asset
 */
export const getAssetPath = (path) => {
    const brand = process.env.VUE_APP_BRAND;

    // Se è un'icona, usa il percorso pubblico
    if (isIconPath(path)) {
        if (brand) {
            return `/brands/${brand}/${path}`;
        }
        return `/${path}`;
    }

    // Per altri tipi di asset (CSS, immagini, etc)
    try {
        // Prova prima a caricare l'asset brandizzato
        if (brand) {
            return require(`@/assets/brands/${brand}/${path}`);
        }
    } catch (e) {
        // Se non trova l'asset brandizzato, non fare nulla e passa al default
    }

    // Carica l'asset di default
    try {
        return require(`@/assets/default/${path}`);
    } catch (e) {
        console.error(`Asset non trovato: ${path}`);
        return null;
    }
};

/**
 * Carica i file CSS del brand o quelli di default
 */
export const loadBrandCSS = () => {
    const cssFiles = [
        'css/variables.css',  // Carica prima le variabili
        'css/custom.css',
        'css/colors.css',
        'css/palette-gradient.css'
    ];

    cssFiles.forEach(file => {
        try {
            if (process.env.VUE_APP_BRAND) {
                require(`@/assets/brands/${process.env.VUE_APP_BRAND}/${file}`);
            } else {
                require(`@/assets/default/${file}`);
            }
        } catch (e) {
            // Se non trova il CSS brandizzato, carica quello di default
            require(`@/assets/default/${file}`);
        }
    });
};