module.exports = {
    colors: {
        primary: '#00B5B8',
        secondary: '#404E67',
        success: '#16D39A',
        info: '#2DCEE3',
        warning: '#FFA87D',
        danger: '#FF7588',
        link: '#009c9f',
        linkHover: '#008385',
        bodyBg: '#F5F7FA'
    },
    // Altre configurazioni del tema
    theme: {
        borderRadius: '0.25rem',
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
    },
    module: {
        // Configurazioni specifiche per i moduli
        sheets: true,
        calendar: true,
        telegram: true,
        wallet: true,
        userWallet: true,
    }
}